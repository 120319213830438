.ChartDemo_main {
  /* margin: 0 auto;
  max-width: 1140px; */
  padding: 4rem 0;
  display: flex;
  flex-direction: column;
  gap: 3rem;
  align-items: center;
}
.divTable {
  display: flex;
  flex-direction: column;
  padding: 3rem 0;
}

.thead {
  border: 2px solid var(--color-black) !important;
  font-weight: 600;
  border-bottom: 0px !important;
  background-color: var(--color-blue);
  color: var(--color-white);
}

.row {
  padding: 0px !important;
  text-align: center;
}
.cell {
  justify-content: center;
}

.row,
.cell {
  display: flex;
  border: 2px solid black;
  border-bottom: 0px;
  width: 100%;
  padding: 10px 0;
}

.chart_chainGraph_imgs_main {
  display: flex;
  gap: 2rem;
}

.chart_chainGraph_imgs_inner {
  height: 400px;
  width: 100%;
}

@media screen and (max-width: 1020px) {
  .chart_chainGraph_imgs_inner {
    height: 270px;
  }
}
@media screen and (max-width: 700px) {
  .chart_chainGraph_imgs_inner {
    height: 200px;
  }
}
@media screen and (max-width: 530px) {
  .chart_chainGraph_imgs_main {
  flex-direction: column;
  }
}

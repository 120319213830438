.Footer_main {
  display: flex;
  margin: 0 auto;
  max-width: 1140px;
  padding: 3rem 0;
  justify-content: space-around;
  /* color: azure; */
  flex-wrap: wrap;
}
.f-link-btn {
  position: fixed;
  bottom: 30px;
  z-index: 155555555;
  right: 30px;
}

.f-link-btn a {
  background-color: #4dc247 !important;
  color: #fff;
  height: 45px;
  display: flex;
  width: 45px;
  justify-content: center;
  align-items: center;
  font-size: 29px;
  border-radius: 100px;
  padding: 0 0 1px 0;
  cursor: pointer;
}

.btn_getintouch:hover{
  border: 2px solid var(--color-red);
  color: var(--color-red);
}
.btn_catalogue{
  background-color: var(--color-blue);
  border: 2px solid var(--color-blue);
  color: var(--color-white);
}
.btn_catalogue:hover{
  background-color: transparent;
  border: 2px solid var(--color-blue);
  color: var(--color-blue);
}
.links_sec_footer {
  display: flex;
  flex-direction: column;
  gap: 0.9rem;
}
.links_sec_footer_inner {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.Galaxy_Chains_block {
  display: flex;
  gap: 0.9rem;
  flex-direction: column;
}
.footer_ph_details {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

/* .footer_link_color,
.link_decor {
  color: azure;
} */

@media screen and (max-width: 800px) {
  .Footer_main {
    gap: 3rem;
    justify-content: space-between;
    margin: 0 3rem;
  }
}

@media screen and (max-width: 1010px) {
  .Footer_main {
    gap: 2rem;
  }
}

@import url("https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,700;1,900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Fira+Sans:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,400;1,800;1,900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Oswald:wght@200;300;400;500;600;700&display=swap");

:root {
  --font-base: "Lato", sans-serif;
  /* --font-base: "Comfortaa", sans-serif; */
  /* --font-alt: "Roboto", sans-serif; */
  --color-white: #ffffff;
  --color-gray: #808080;
  --color-lightgray: lightgray;
  --color-whitespmoke: #f5f5f5;
  --color-darkgray: #a9a9a9;
  --color-brown: brown;
  --color-red: #e3001b;
  --color-blue: #162a83;
  --color_blue_shade: #0d4598;
  --color_orange_shade: #f29400;
  --color-black: #0d0202;
}

* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  scroll-behavior: smooth;
  font-family: var(--font-base);
  /* font-family: "Lato", sans-serif !important; */
  initial-scale: 1 !important;
  /* user-select: none; */
}

h1 {
  font-weight: 800;
  margin: 0;
}

.underline {
  width: 10rem;
  height: 0.25rem;
  background: orange;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 2rem;
  margin-top: 1rem;
}

.heading {
  display: flex;
  letter-spacing: 1px;
  gap: 0.5rem;
  font-size: 1.2rem;
  justify-content: center;
  margin: 2rem 0px;
  margin-bottom: 0px;
  color: var(--color-blue);
  text-transform: capitalize;
}

.link_decor_footer {
  color: black;
  text-decoration: none;
}

.btn {
  background-color: var(--color-red);
  padding: 10px 30px;
  color: white;
  font-weight: bold;
  font-size: 16px;
  cursor: pointer;
  transition: all ease-in-out 0.5s;
  border: 2px solid var(--color-red);
}
.btn:hover {
  background-color: transparent;
  border: 2px solid;
}

.btn_black {
  background-color: #19171a;
  border: 2px solid #19171a;
  transition: all ease-in-out 0.5s;
}
.btn_black:hover {
  border: 2px solid;
  background-color: transparent;
}

.hero_path {
  height: 150px;
  background: var(--color-blue);
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
}

a {
  color: black;
  text-decoration: none;
}

@media screen and (max-width: 768px) {
  .heading {
    font-size: 18px;
  }
}

@media screen and (max-width: 500px) {
  .heading {
    font-size: 14px;
  }
}

@media screen and (max-width: 400px) {
  .heading {
    font-size: 12px;
  }
}

.HomePgHero_main {
  display: flex;
}
.HomePgHero_one {
  background: linear-gradient(
    90deg,
    rgba(13, 69, 152, 1) 100%,
    rgba(0, 212, 255, 1) 100%
  );

  /* background: var(--color_blue_shade); */
  color: aliceblue;
  width: 50%;
  height: 550px;
  display: flex;
  align-items: center;
  justify-content: center;
  letter-spacing: 2px;
}
.HomePgHero_two {
  background: var(--color_orange_shade);
  color: aliceblue;
  width: 50%;
  height: 550px;
  display: flex;
  align-items: center;
  justify-content: center;
  letter-spacing: 2px;
}

.HomePgHero_name_cat {
  font-size: 30px;
  letter-spacing: 3px;
}
.HomePgHero_name_MANUFACTURER {
  font-size: 18px;
}

.HomePgHero_name_head {
  font-size: 50px;
  font-weight: 900;
}

@media screen and (max-width: 768px) {
  .HomePgHero_name_head {
    font-size: 40px;
  }
  .HomePgHero_main {
    flex-direction: column;
  }
  .HomePgHero_one,
  .HomePgHero_two {
    height: 400px;
    width: 100%;
  }
}
/* @media screen and (max-width: 568px) {
  .HomePgHero_name_MANUFACTURER {
    font-size: 16px;
  }
  .HomePgHero_name_cat {
    font-size: 26px;
  }
  .HomePgHero_name_head {
    font-size: 30px;
  }

  .HomePgHero_one,
  .HomePgHero_two {
    height: 300px;
  }
} */

/* @media screen and (max-width: 450px) {
  .HomePgHero_one,
  .HomePgHero_two {
    height: 250px;
  }

  .HomePgHero_name_MANUFACTURER {
    font-size: 12px;
  }

  .HomePgHero_name_cat {
    font-size: 22px;
  }

  .HomePgHero_name_head {
    font-size: 26px;
  }

  .btn_home_hero {
    padding: 7px;
  }
} */

/* @media screen and (max-width: 400px) {
  .HomePgHero_one,
  .HomePgHero_two {
    height: 220px;
  }

   .HomePgHero_name_MANUFACTURER {
    font-size: 10px;
  }

  .HomePgHero_name_cat {
    font-size: 20px;
  }

  .HomePgHero_name_head {
    font-size: 24px;
  }

   .btn_home_hero {
    padding: 5px;
  }
} */
